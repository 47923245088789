const NextI18Next = require('next-i18next').default
const path = require('path')

module.exports = new NextI18Next({
  debug: false,
  otherLanguages: ['en', 'th'],
  defaultNS: 'translate',
  defaultLanguage: 'en',
  fallbackLng: 'en',
  localePath: path.resolve('./public/static/locales'),
})
